import Handlebars from 'handlebars'
import moment from 'moment-timezone'

// Math operators
Handlebars.registerHelper('add', (a, b) => Number(a) + Number(b))
Handlebars.registerHelper('sub', (a, b) => Number(a) - Number(b))
Handlebars.registerHelper('mul', (a, b) => Number(a) * Number(b))
Handlebars.registerHelper('div', (a, b) => Number(a) / Number(b))
Handlebars.registerHelper('mod', (a, b) => Number(a) % Number(b))

// Math functions.
Handlebars.registerHelper('floor', (n) => Math.floor(Number(n)))
Handlebars.registerHelper('ceil', (n) => Math.ceil(Number(n)))

// Conditional operators.
Handlebars.registerHelper('eq', (a, b) => a === b)
Handlebars.registerHelper('gt', (a, b) => a > b)
Handlebars.registerHelper('gte', (a, b) => a >= b)
Handlebars.registerHelper('lt', (a, b) => a < b)
Handlebars.registerHelper('lte', (a, b) => a <= b)
Handlebars.registerHelper('ne', (a, b) => a !== b)

// String operators.
Handlebars.registerHelper('substr', (from, length, v) => String(v).substr(from, length))

// Format functions.
Handlebars.registerHelper('formatNumber', (n, options) => {
  const {
    decimals = 0,
    decimalPoint = ',',
    thousandsSeparator = '.'
  } = options.hash || {}

  let [major, minor] = Number(n).toFixed(decimals).split('.')
  major = major.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator)
  return minor ? `${major}${decimalPoint}${minor}` : major
})

Handlebars.registerHelper('formatDate', (v, options) => {
  const {
    format,
    timezone = 'Europe/Amsterdam'
  } = options.hash || {}

  if (v === 'now') {
    v = moment()
  } else {
    v = moment(v)
  }

  return v.tz(timezone).format(format)
})

export default Handlebars
