import moment from 'moment'
import SimpleSchema from '@qiri/simpl-schema'
import ProductModel from '../data/Product'
import ProductPromotionModel from '../data/ProductPromotion'
import OrderBy from './OrderBy'

export const Configuration = new SimpleSchema({

})

export const Output = ({model} = {}) => new SimpleSchema({
  /*'product': {
    type: model ? model('Product').type : ProductModel,
    label: 'Product',
    table: 'Producten'
  },*/
  'productPromotion': {
    type: model ? model('ProductPromotion').type : ProductPromotionModel,
    label: 'Productpromotie',
    table: 'Productpromoties'
  }
})

export const Preview = ({customFields}) => new SimpleSchema(Object.assign(
  customFields
    .filter(customField => customField.model === 'ProductPromotion' && customField.type === 'foreignKey')
    .reduce(
      (acc, cur) => {
        acc[cur.name] = {
          type: String,
          label: cur.name,
          render (record) {
            return record.productPromotion[cur.name]
          }
        }
        return acc
      },
      {}
    ),
  {
    'startDate': {
      type: String,
      label: 'Geldig vanaf',
      render (record) {
        return moment(record.productPromotion.startDate).format('D-M-YYYY')
      }
    },
    'endDate': {
      type: String,
      label: 'Geldig tot en met',
      render (record) {
        return moment(record.productPromotion.endDate).format('D-M-YYYY')
      }
    },
    'productCount': {
      type: String,
      label: 'Aantal producten',
      render (record) {
        return record.productPromotion.products.length
      }
    }
  }
))

export const Request = new SimpleSchema({
  'product': {
    type: String,
    label: 'Product',
    description: 'Het product waarvan productpromoties getoond moeten worden.',
    optional: true
  },
  'productPromotion': {
    type: String,
    label: 'Productpromotie',
    description: 'De productpromotie welke opgehaald moet worden.',
    optional: true
  },
  'startDate': {
    type: Date,
    label: 'Start datum',
    description: 'De start datum van de productpromotie. Indien leeg wordt ‘vandaag’ gebruikt.',
    optional: true
  },
  'endDate': {
    type: Date,
    label: 'Eind datum',
    description: 'De eind datum van de productpromotie (optioneel).',
    optional: true
  },
  'useCategory': {
    type: Boolean,
    label: 'Gebruik product categorieën',
    description: 'Zoek productpromoties op basis van product categorieën i.p.v. exacte producten.',
    optional: true,
    options: {
      'Ja': 'true',
      'Nee': 'false'
    }
  },
  'skipNoProducts': {
    type: Boolean,
    label: 'Negeer productpromoties zonder producten',
    description: 'Indien een productpromotie geen producten heeft, gebruik deze dan niet als suggestie.',
    optional: true,
    options: {
      'Ja': 'true',
      'Nee': 'false'
    }
  },
  'productLimit': {
    type: SimpleSchema.Integer,
    label: 'Limiet producten per productpromotie',
    description: 'Maximaal aantal producten om te tonen per productpromotie.',
    min: 1,
    optional: true
  },
  'filter': {
    type: Array,
    label: 'Filters',
    optional: true
  },
  'filter.$': {
    type: String
  },
  'limit': {
    type: SimpleSchema.Integer,
    label: 'Limiet',
    description: 'Maximaal aantal resultaten dat wordt getoond.',
    min: 1,
    defaultValue: 20
  }/*,
  'orderBy': OrderBy.arraySchema({
    defaultValue: [{ field: 'factor', order: 'desc' }]
  }),
  'orderBy.$': OrderBy.elementSchema({
    indexFields: Output
  })*/
})

export const RequestConfiguration = new SimpleSchema({
  'maxLimit': {
    type: SimpleSchema.Integer,
    label: 'Limiet',
    description: 'Maximaal aantal suggesties in een enkele API-response',
    min: 1,
    defaultValue: 50
  }
})
